import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import { ToastContainer } from 'react-toastify';

import '../../node_modules/font-awesome/scss/font-awesome.scss';
import 'react-toastify/dist/ReactToastify.css';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";
import Login from '../screens/Login'
import Register from '../screens/Register'
import OfficerRegister from '../screens/OfficerRegister'
import OfficerYearlyExpenseChart from '../screens/hubStaffApp/OfficerYearlyExpenseChart'
import PendingIncomeChart from '../screens/hubStaffApp/PendingIncomeChart'
import PendingExpenseChart from '../screens/hubStaffApp/PendingExpenseChart'
import {getQueryParams} from '../utils/Functions'
import withWordingContext from "../hoc/withWordingContext"

import api from '../utils/Api'

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

export const setBcAndTitle = (path) => {
  const {bcPath} = bc(path)

  document.getElementById('bc-1').innerHTML = bcPath[0]

  if (bcPath[1]) {
    if (document.getElementById('bc-2')) {
      document.getElementById('bc-2').innerHTML = bcPath[1]
    } else {
      document.getElementById('bc-1').insertAdjacentHTML('afterend',
      `<i class="fa fa-chevron-right mx-3"></i><h5 id="bc-2" class="mb-0">${bcPath[1]}</h5>`)
    }
  }

  document.title = `${bcPath[0]}` + ' | HubSwitch Pro';
}

export const bc = (path, typeName) => {
  let bcPath = ['', '']
  let main = ''
  const stylistWording = typeName?.match(/คลินิก/) ?"Doctors":"Stylists"

  if (path.match(/crm/)) {
    main = 'Customer Relationship Management'

    bcPath[0] = 'ลูกค้า'
    if (!path.match(/reward/)) {
      bcPath[1] = 'ประวัติลูกค้า'
    } else {
      bcPath[1] = 'จัดการสะสมแต้ม'
    }
  } else if (path.match(/page/)) {
    main = 'Page'
    bcPath[0] = 'Home'
    if (path.match(/edit\-profile/)) {
        bcPath[0] = 'Edit Profile'
    } else if (path.match(/stylist/)) {
        bcPath[0] = stylistWording
    } else if (path.match(/post/)) {
        bcPath[0] = 'Posts'
    } else if (path.match(/service/)) {
        bcPath[0] = 'Services'
    } else if (path.match(/promotion/)) {
        bcPath[0] = 'Promotions'
    } else if (path.match(/coupon/)) {
        bcPath[0] = 'Coupons'
    } else if (path.match(/review/)) {
        bcPath[0] = 'Reviews'
    } else if (path.match(/group/)) {
        bcPath[0] = 'Groups'
    }

    if (path.match(/adjust-service-time/)) {
        bcPath = [stylistWording, 'Adjust Services Time']
     }
  } else if (path.match(/calendar/)) {
    main = 'Calendar'
    bcPath[0] = 'Calendar'
  } else if (path.match(/message/)) {
    main = 'Message'
    bcPath[0] = 'ข้อความ'
    if (path.match(/setting/)) {
      bcPath[0] = 'ตั้งค่าข้อความ'
    }
  } else if (path.match(/payment/)) {
    main = 'Payment'
    bcPath[0] = 'การชำระเงิน'
    bcPath[1] = 'รอการชำระเงิน'

    if (path.match(/history/) || getQueryParams(window.location.search).history) {
      bcPath[1] = 'ประวัติการชำระเงิน'
      if (path.match(/all-sales/)) {
        bcPath[1] = 'รายงานขาย'
      }
      if (path.match(/lab/)) {
        bcPath[1] = 'ยอดขายแยกตามแล็บ'
      }
    } else if (path.match(/create/)) {
      bcPath[1] = 'สร้างใบเสร็จรับเงิน'
    } else if (path.match(/daily-sale/)) {
      bcPath[1] = 'รายการยอดขายรายวัน'
    }
  } else if (path.match(/my\-business/)) {
    main = 'My Business'
    bcPath[0] = 'ข้อมูลธุรกิจ'
  } else if (path.match(/setting/)) {
    main = 'Setting'
    bcPath[0] = 'ตั้งค่าสิทธิ์การใช้งาน'
  } else if (path.match(/notification/)) {
    main = 'My Business'
    bcPath[0] = 'การแจ้งเตือน'
  } else if (path.match(/account/)) {
    main = 'Account'
    bcPath[0] = 'Dashboard'
    if (path.match(/book/)) {
      bcPath[0] = 'สมุดรายวัน'
      if (path.match(/general/)) {
        bcPath[1] = 'สมุดรายวันทั่วไป'
      } else if (path.match(/purchase/)) {
        bcPath[1] = 'สมุดรายวันซื้อ'
      } else if (path.match(/sale/)) {
        bcPath[1] = 'สมุดรายวันขาย'
      } else if (path.match(/expense/)) {
        bcPath[1] = 'สมุดรายวันจ่าย'
      } else if (path.match(/income/)) {
        bcPath[1] = 'สมุดรายวันรับ'
      }
    } else if (path.match(/report/)) {
      bcPath[0] = 'รายงานด้านบัญชี'
      if (path.match(/demo/)) {
        bcPath[1] = 'งบทดลอง'
      } else if (path.match(/pnl/)) {
        bcPath[1] = 'งบกำไรขาดทุน'
      } else if (path.match(/status/)) {
        bcPath[1] = 'งบแสดงฐานะการเงิน'
      } else if (path.match(/activity/)) {
        bcPath[1] = 'รายงานการเคลื่อนไหว'
      } else if (path.match(/debtor/)) {
        bcPath[1] = 'ลูกหนี้ตามเอกสาร'
      } else if (path.match(/creditor/)) {
        bcPath[1] = 'เจ้าหนี้ตามเอกสาร'
      }
    } else if (path.match(/plan/)) {
      bcPath[0] = 'ผังบัญชี'
    }  else if (path.match(/quotation/)) {
      bcPath[0] = 'ใบเสนอราคา'
    } else if (path.match(/multi-billing/)) {
      bcPath[0] = 'ใบวางบิลรวม'
    } else if (path.match(/billing/)) {
      bcPath[0] = 'ใบวางบิล/ใบแจ้งหนี้'
    } else if (path.match(/credit-note/)) {
      bcPath[0] = 'ใบลดหนี้'
    } else if (path.match(/debit-note/)) {
      bcPath[0] = 'ใบเพิ่มหนี้'
    } else if (path.match(/purchase\/vat/)) {
      bcPath[0] = 'ภาษีซื้อ'
    } else if (path.match(/withholding/)) {
      bcPath[0] = 'ภาษีหัก ณ ที่จ่าย'
    } else if (path.match(/expense/)) {
      bcPath[0] = 'ค่าใช้จ่าย'
    } else if (path.match(/vat/)) {
      bcPath[0] = 'ภาษีขาย'
    } else if (path.match(/salary/)) {
      bcPath[0] = 'เงินเดือน'
      if (path.match(/list/)) {
        bcPath[1] = 'รายการเงินเดือน'
      } else if (path.match(/create/)) {
        bcPath[1] = 'สร้างรายการเงินเดือน'
      }
    }
  } else if (path.match(/human-resources/)) {
    main = 'Human Resource'
    bcPath[0] = 'Human Resource'
      if (path.match(/employees/)) {
          bcPath[0] = 'ข้อมูลพนักงาน'
      } else if (path.match(/employee-groups/)) {
          bcPath[0] = 'ข้อมูลพนักงาน'
          bcPath[1] = 'กลุ่มพนักงาน'
      } else if (path.match(/employee-groups/)) {
          bcPath[0] = 'ข้อมูลพนักงาน'
          bcPath[1] = 'ข้อมูลพนักงาน'
      }
  } else if (path.match(/inventory/)) {
    main = 'Inventory'
    bcPath[0] = 'Dashboard'
    if (path.match(/category/)) {
      bcPath[0] = 'คลังสินค้า'
    } else if (path.match(/product/)) {
      bcPath[0] = 'คลังสินค้า'
      bcPath[1] = 'สินค้า'
    } else if (path.match(/report/)) {
      bcPath[0] = 'รายงานสินค้า'
    } else if (path.match(/receipt/)) {
      bcPath[0] = 'ใบรับสินค้า'
    } else if (path.match(/purchase/)) {
      bcPath[0] = 'ใบสั่งสินค้า'
    }
  } else if (path.match(/list-package/)) {
    main = 'List Package'
    bcPath[0] = 'List Package'
    if (path.match(/appointments/)) {
      bcPath[0] = 'ลิสต์นัดหมาย'
    } else if (path.match(/treatments/)) {
      bcPath[0] = 'ลิสต์การรักษา'
    } else if (path.match(/diagnoses/)) {
      bcPath[0] = 'ลิสต์การวินิจฉัย'
    } else if (path.match(/medicine-and-goods/)) {
      bcPath[0] = 'ลิสต์ยา'
    } else if (path.match(/lab-work/)) {
      bcPath[0] = 'ลิสต์งานแลป'
    }
  } else if (path.match(/x-ray/)) {
    main = 'X-Ray'
    bcPath[0] = 'X-Ray'
    if (path.match(/extra-oral-film/)) {
      bcPath[0] = 'Extra Oral Film'
    } else if (path.match(/intra-oral-film/)) {
      bcPath[0] = 'Intra Oral Film'
    }
  } else if (path.match(/lab/)) {
    main = 'Lab'
    bcPath[0] = 'Lab'
    if (path.match(/lab/) && getQueryParams(window.location.search)?.lab_state === 'waiting') {
      bcPath[0] = 'แล็บ'
      bcPath[1] = 'รายการรอนำส่ง'
    } else if (path.match(/lab/) && getQueryParams(window.location.search)?.lab_state === 'sent') {
      bcPath[0] = 'แล็บ'
      bcPath[1] = 'รายการส่งออก'
    } else if (path.match(/lab/) && getQueryParams(window.location.search)?.lab_state === 'receive') {
      bcPath[0] = 'แล็บ'
      bcPath[1] = 'รายการได้รับ'
    } else if (path.match(/lab-work/)) {
      bcPath[0] = 'บริษัทแล็บ'
      bcPath[1] = 'ลิสต์งานแล็บ'
    } else if (path.match(/lab-company/)) {
      bcPath[0] = 'บริษัทแล็บ'
      bcPath[1] = 'รายชื่อบริษัท'
    } else if (path.match(/lab-report/)) {
      bcPath[0] = 'รายงานแล็บ'
      bcPath[1] = 'สรุปยอดรายการ'
    } else if (path.match(/lab-follow-report/)) {
      bcPath[0] = 'รายงานแล็บ'
      bcPath[1] = 'รายงานติดตามผล'
    } else if (path.match(/lab/)) {
      bcPath[0] = 'แล็บ'
      bcPath[1] = 'รายการทั้งหมด'
    }
  } else {
    main = 'Home'
    bcPath[0] = 'Dashboard'
  }

  return {bcPath, main}
}

class App extends Component {
    state = {
        loaded: false
    }

    componentDidMount() {
      const { location } = this.props;
      if (location.pathname !== '/register' && location.pathname !== '/login') {
        api.request('admin-user/profile', (user, success)=>{
          if (user && (user.shop_id !== this.props.currentShopID)) {
            const token = localStorage.getItem('api_token')
            this.props.setCurrentShopID(token ? user.shop_id : null)
          }
          if (success) {
            localStorage.setItem('user', JSON.stringify(user))
          }
          this.setState({loaded: true})
        })
      } else {
        this.setState({loaded: true})
      }
    }

    componentDidUpdate(prevProps) {
      const { location } = prevProps;
      if (location.pathname == '/login' && this.props.location.pathname != '/login') {
        api.request('admin-user/profile', (user, success)=>{
          if (user && (user.shop_id !== this.props.currentShopID)) {
            this.props.setCurrentShopID(user.shop_id)
          }
        })
      }
    }

    render() {
      const {match, location, layoutType, navStyle, locale, authUser, initURL} = this.props;
      const {loaded} = this.state

      if (location.pathname.match(/hubstaff/g)) {
        return <Aux>
          <Switch>
            <Route path="/hubstaff/officer-yearly-expense" component={OfficerYearlyExpenseChart} />
            <Route path="/hubstaff/pending-income" component={PendingIncomeChart} />
            <Route path="/hubstaff/pending-expense" component={PendingExpenseChart} />
          </Switch>
        </Aux>
      }


      if (location.pathname == '/register') {
        return ( <Route path="/register" component={Register} /> );
      }

      if (location.pathname == '/officer-register') {
        return ( <Route path="/officer-register" component={OfficerRegister} /> );
      }

      // if (location.pathname == '/login') {
      //   return ( <Route path="/login" component={Login} /> );
      // }

      if (location.pathname !== '/login' && !localStorage.getItem('api_token')) {
        return ( <Redirect to={{pathname: '/login', state: {from: location} }}/> );
      }

      if (!loaded) {
        return null
      }

        const menu = routes.map((route, index) => {
          return (route.component) ? (
              <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                      <route.component {...props} />
                  )} />
          ) : (null);
        });

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            {menu}
                            <Route path="/login" component={Login} />
                            <Route path="/" component={AdminLayout} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
                <ToastContainer autoClose={3000} />
            </Aux>
        );
    }
}

export default withWordingContext(App);
