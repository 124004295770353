import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Row, Col, Container, Card, Table, Button, Form, InputGroup, FormControl,
  Accordion, Modal, Badge, Dropdown, Fade
} from 'react-bootstrap';
import '../../assets/scss/style.scss';
import Aux from "../../hoc/_Aux";
import Fullscreen from "react-full-screen";
import { toast } from 'react-toastify';
import Select from 'react-select'
import api from '../../utils/Api'

export default function BusinessInfo({ onNext, onCancel, isPersonal }) {

  const [form, setForm] = useState({
    shop_name: null,
    phone: null,
    id_card: null,
    address: null,
    tax_type: 'none',
    business_type: 'co',
    business_name: null,
    type_id: null
  })
  const [businessTypes, setBusinessTypes] = useState([])

  const fetch = () => {
    api.request(`business-types`, (businessData) => {
      const formatBusinessData = businessData.map((item) => ({ label: item.name.th, value: item._id }))
      setBusinessTypes(formatBusinessData)
    })
  }

  useEffect(() => {
    fetch()
  }, [])

  return (
    <Aux>
      <div className="full-screen bg-white">
        <Container fluid>
          <Row>
            <Col className="register-process-indicator column-center py-4" sm={12} lg={5}>
              <h3 className="text-white">ข้อมูลธุรกิจ</h3>
              <i className="fa fa-building text-white register-process-indicator-icon"
              ></i>
              {!isPersonal &&
                <div className="row-vcenter">
                  <div className="register-process-indicator-bar active" />
                  <div className="register-process-indicator-bar mx-2" />
                  <div className="register-process-indicator-bar" />
                </div>
              }
            </Col>
            <Col sm={12} lg={7}>
              <div className="d-flex flex-column justify-content-around align-items-center h-100">
                <Row style={{ width: 500, maxWidth: '100%' }}>
                  <Col xs={12}>
                    <h3 className="text-primary text-center">เริ่มต้นใส่ข้อมูลของคุณ</h3>
                  </Col>
                  {!isPersonal &&
                    <>
                      <Col xs={12} c className="mt-3">
                        <div>
                          <label>ชื่อธุรกิจ*:</label>
                        </div>
                        <div>
                          <Form.Control
                            placeholder="ชื่อบริษัท/ธุรกิจ(หากไม่มีให้ใส่ชื่อร้าน)"
                            onChange={(e) => setForm({
                              ...form,
                              business_name: e.target.value
                            })}
                          />
                        </div>
                      </Col>

                      <Col xs={12} c className="mt-3">
                        <div>
                          <label>ชื่อร้าน*:</label>
                        </div>
                        <div>
                          <Form.Control
                            placeholder="ชื่อร้านของคุณ"
                            onChange={(e) => setForm({
                              ...form,
                              shop_name: e.target.value
                            })}
                          />
                        </div>
                      </Col>
                    </>
                  }
                  <Col xs={12} c className="mt-3">
                    <div>
                      <label>ประเภทธุรกิจ*:</label>
                    </div>
                    <div>
                      <Select
                        options={businessTypes}
                        onChange={({ value }) => {
                          const id = value
                          setForm({ ...form, type_id: value })
                        }}

                        value={businessTypes.find((item) => item.value === form.type_id)}
                      />
                    </div>
                  </Col>


                  <Col xs={12}>
                    <div className="switch d-inline">
                      <Form.Control defaultChecked={false}
                        onChange={(e) => setForm({
                          ...form,
                          tax_type: e.target.checked ? 'vat' : 'none'
                        })}
                        type="checkbox" id={'vat'} />
                      <Form.Label htmlFor={"vat"} className="cr mr-2" />
                      จดภาษีมูลค่าเพิ่มแล้ว
                    </div>
                  </Col>
                  {!isPersonal && <>
                    <Col xs={12} className="mt-3">
                      <div>
                        <label>สมัครในนาม:</label>
                      </div>
                      <Form.Group className={"row-vcenter mb-0 secondary-radio"}>
                        <Form.Check
                          inline
                          custom
                          defaultChecked
                          type="radio"
                          label={'บริษัท/ห้างหุ้นส่วนจำกัด'}
                          name={'business_type'}
                          value={'co'}
                          id={'business_type'}
                          className="mr-3"
                          onChange={(e) => setForm({
                            ...form,
                            business_type: 'co'
                          })}
                        />
                        <Form.Check
                          inline
                          custom
                          //checked={perm.indexOf(item.value)==-1}
                          label={'บุคคลธรรมดา/ฟรีแลนซ์'}
                          name={'business_type'}
                          id={'person'}
                          value={'person'}
                          type="radio"
                          //feedback={this.state.supportedRadio ? false : "More example invalid feedback text."}
                          onChange={(e) => setForm({
                            ...form,
                            business_type: 'person'
                          })}
                        />
                      </Form.Group>
                    </Col>
                  </>
                  }
                  <Col xs={12} className="mt-3">
                    <div>
                      <label>เลขประจำตัวผู้เสียภาษี 13 หลัก*:</label>
                    </div>
                    <div>
                      <Form.Control
                        placeholder=""
                        maxLength={13}
                        onChange={(e) => setForm({
                          ...form,
                          id_card: e.target.value
                        })}
                      />
                    </div>
                  </Col>
                  <Col xs={12} className="mt-3">
                    <div>
                      <label>เบอร์ติดต่อ*:</label>
                    </div>
                    <div>
                      <Form.Control
                        placeholder=""
                        maxLength={10}
                        onChange={(e) => setForm({
                          ...form,
                          phone: e.target.value
                        })}
                      />
                    </div>
                  </Col>
                  <Col xs={12} className="mt-3">
                    <div>
                      <label>ที่อยู่:</label>
                    </div>
                    <div>
                      <Form.Control
                        as="textarea"
                        placeholder=""
                        onChange={(e) => setForm({
                          ...form,
                          address: e.target.value
                        })}
                      />
                    </div>
                  </Col>
                  <Col xs={12} className="row-center mt-3">
                    <Button onClick={() => onCancel()} className="mr-3" size="md" variant="dark">Back</Button>
                    <Button onClick={() => {
                      if (!isPersonal) {
                        if (!form.business_name) {
                          toast.warn('กรุณากรอกชื่อธุรกิจ')
                          return
                        }

                        if (!form.shop_name) {
                          toast.warn('กรุณากรอกชื่อร้าน')
                          return
                        }
                      }

                      if (!form.type_id) {
                        toast.warn('กรุณาเลือกประเภทธุรกิจ')
                        return
                      }

                      if (!form.phone) {
                        toast.warn('กรุณากรอกเบอร์ติดต่อ')
                        return
                      }

                      onNext(form)
                    }} size="md" variant="secondary">{isPersonal ? `Register` : `Next`}</Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Aux>
  )
}
